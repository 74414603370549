<template>
    <div class="wrapper">
        <iframe src="https://shop.cik-solutions.com/widgets/checkout/cikcart" frameborder="0" class="cart"></iframe>
        <button class="overlay-button" @click="toggleCart()"></button>
        <div v-show="open"  class="cart-inner">
            <iframe  src="https://shop.cik-solutions.com/checkout/cikoffcanvas" frameborder="0" ></iframe>
            <a class="button" href="https://shop.cik-solutions.com/checkout/cart">Warenkorb anzeigen</a>
        </div>
    </div>
    
    
    
</template>

<script>
export default {
    name: 'App',
    data() {
        return {
            open: false,
        }
    },
    methods: {
        toggleCart() {
            this.open = !this.open;
        }
    }
}
</script>

<style>
html, body {
    padding: 0;
    margin: 0;
}
.wrapper {
    height: 40.17px;
    width: 140px;
    position: relative;
    margin-left: auto;
    z-index: 1;
}
.cart {
    height: 100%;
    width: 100%;
}
.wrapper button.overlay-button {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
}
.cart-inner {
    position: absolute;
    right: 0;
    top: 100%;
    width: 351px;
    height: 500px;
    max-height: 100vh;
    border: 1px solid #eee;
    background-color: white;
}
.cart-inner iframe {
    position: relative;
    width: 100%;
    height: calc( 100% - 42px - 20px);
}
.cart-inner .button {
    font-family: "Open Sans",sans-serif;
    margin-top: auto;
    color: #fff;
    background-color: #ec7404;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    padding: 2px 12px;
    font-size: 1rem;
    line-height: 38px;
    border-radius: 3px;
    border: 0px;
    margin: 10px;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    text-align: center;
}
.cart-inner .button:hover {
    background-color: #c66203;
}
</style>